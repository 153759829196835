import React from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PostCard from "../components/postCard"

// import "../utils/global.scss"
import "../utils/normalize.css"
import "../utils/css/screen.css"
//TODO: switch to staticQuery, get rid of comments, remove unnecessary components, export as draft template
const BlogIndex = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
  let postCounter = 0
  

 
  return (
    <Layout title={siteTitle}>
      <SEO
        title="All posts"
        keywords={[`blog`, `gatsby`, `javascript`, `react`]}
      />
      {/* <Bio /> */}
      {data.site.siteMetadata.description && (
        <header className="page-head">
       <h1> Big money and stupid playing go hand in hand- A banker who became a .. gambler ! - No more free e sports </h1>
          <h2 className="page-head-title">
            {data.site.siteMetadata.description}
          </h2>
        </header>
      )}
      <div className="post-feed">
        {posts.map(({ node }) => {
          postCounter++
          return (
            <PostCard
              key={node.fields.slug}
              count={postCounter}
              node={node}
              postClass={`post`}
            />
          )
        })}
      </div>
      
                  <span class="site-foot">  <p> 
                  
        check also:
        
        <a href="https://www.tulsabankruptcyattorney.pro/oklahoma-bankruptcy-help/filing-medical-bankruptcy-in-oklahoma">how do i file for bankruptcy in oklahoma</a>
    How to make money online is possible through affiliate marketing. Affiliate marketing is a way of promoting other products through a affiliate link and earn commission from it while in sleep. <a href="https://yarnews.in/how-to-earn-money-online-best-ideas/" target="_blank">how to make money online</a> Here is the link for successss
Visit <a href="https://www.financiallygenius.com/zacks-trade/">https://financiallygenius.com</a> to find out more regarding zacks trade       

The business benefits of ABW are also clear. <a href="https://www.deskflex.com/">Activity based work environment</a> lead to a more efficient use of office resources&mdash;affording businesses to have fewer desks and fewer conference rooms, while simultaneously improving the utilization of those spaces and adding new degrees of freedom to office space design.
about Whatsapp GB and Facebook with our partner partner <a href="https://whatsapp-gb.blog.br/whatsapp-gb-2022/" target="_blank">www.whatsapp-gb.blog.br/whatsapp-gb-2022</a>  Everything about apps like facebook, instagram and whatsapp gb in one place

</p> </span>
    </Layout>
  )
}
    
const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 1360) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <BlogIndex location={props.location} props data={data} {...props} />
    )}
  />
)
